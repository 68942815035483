import React, { useContext } from "react";
import ProfileContext from "../../Context";
import { H3 } from "prisma-component-library";

function PersonalInformation() {
  const { userData } = useContext(ProfileContext);

  return (
    <div>
      <H3>Personal information</H3>

      <ul>
        <li>
          <div>Email</div>
          {userData ? userData.email : ""}
        </li>
        <li>
          <div>Country</div>
          {userData ? userData.country : ""}
        </li>
        <li>
          <div>Phone</div>
          {userData ? userData.phoneNumber : ""}
        </li>
        <li>
          <div>Company</div>
          {userData ? userData.companyName : ""}
        </li>
      </ul>
    </div>
  );
}

export default PersonalInformation;
