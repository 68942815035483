import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SubscriptionCard from "../Common/Cards/SubscriptionCard";
import useSubscriptionTypes from "../../utils/customHooks/useSubscriptionTypes";

function SubscriptionsListAll() {
  const subscriptionData = useSubscriptionTypes();

  if (subscriptionData) {
    return (
      <SubscriptionCards>
        {subscriptionData.map(
          (subscription: {
            body: Object;
            description: string;
            title: string;
            imageUrl: string;
          }) => (
            <SubscriptionCard
              key={subscription.title}
              body={subscription.body}
              description={subscription.description}
              name={subscription.title}
              imageUrl={subscription.imageUrl}
            />
          )
        )}
      </SubscriptionCards>
    );
  } else return null;
}

export default SubscriptionsListAll;

const SubscriptionCards = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1044px) {
    flex-flow: column-reverse;
    align-items: center;
  }
`;
