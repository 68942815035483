import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { LargeCard } from "prisma-component-library";
import { NEWS_PREVIEW } from "../../constants/paths";
import ProgressSteps from "../Common/ProgressSteps";

interface NewsPreviewProps {
  _id: string;
  imageUrl: string;
  title: string;
  length: number;
}

function NewsCard() {
  const [newsPreviewData, setNewsPreviewData] = useState<NewsPreviewProps[]>(
    []
  );
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(0);

  useEffect(() => {
    fetch(NEWS_PREVIEW)
      .then((res: any) => res.json())
      .then((data: NewsPreviewProps[]) => {
        setNewsPreviewData(data);
      })
      .catch((err: any) => {
        console.error("Oh no, error occured: ", err);
      });
  }, []);

  useEffect(() => {
    /* assign interval to a variable to clear it, to make cards show new 
      news item every 10 seconds */
    const id = setInterval(() => {
      setSelectedItemIndex(selectedItemIndex => {
        const newsPreviewDataLength = newsPreviewData.length + 1;
        if (newsPreviewDataLength === selectedItemIndex) {
          return 0;
        } else return selectedItemIndex + 1;
      });
    }, 10000);

    setIntervalId(id);
    return () => clearInterval(intervalId);
  }, []);

  const clickOnProgressStep = (i: any) => {
    setSelectedItemIndex(parseInt(i.target.id, 10));
    clearInterval(intervalId);
  };

  if (newsPreviewData.length > 0) {
    return (
      <LargeCard
        imageUrl={
          newsPreviewData ? newsPreviewData[selectedItemIndex].imageUrl : ""
        }
        displayOverlay
      >
        <Content>
          <NavLink to={`article/${newsPreviewData[selectedItemIndex]._id}`}>
            {newsPreviewData ? newsPreviewData[selectedItemIndex].title : ""}
            <em>
              <br />
              {newsPreviewData
                ? newsPreviewData[selectedItemIndex].length
                : 0}{" "}
              minute read
            </em>
          </NavLink>
          <ProgressSteps
            onClick={clickOnProgressStep}
            length={newsPreviewData.length}
            selectedStep={selectedItemIndex}
          />
        </Content>
      </LargeCard>
    );
  }
  return null;
}

export default NewsCard;

const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  a {
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    flex-grow: 1;
    text-decoration: none;
  }
`;
