import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Number } from "prisma-component-library";
const BlockContent = require("@sanity/block-content-to-react");

function IntroTextBoxes() {
  const [sectionData, setSectionData] = useState();

  useEffect(() => {
    fetch("landingpage/sections")
      .then((res: any) => res.json())
      .then(data => {
        setSectionData(data);
      })
      .catch((err: any) => {
        console.error("Oh no, error occured: ", err);
      });
  }, []);

  if (sectionData) {
    return (
      <IntroTextBoxesContainer>
        {sectionData.map((content: { body: Object; title: string }) => (
          <div className="text_box" key={content.title}>
            <Number>{content.title}</Number>
            <BlockContent blocks={content.body} />
          </div>
        ))}
      </IntroTextBoxesContainer>
    );
  } else return null;
}

export default IntroTextBoxes;

const IntroTextBoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
  align-items: center;
  max-width: 1136px;
  margin: auto;

  p {
    font-size: 28px;
    line-height: 40px;
  }

  > :nth-child(odd) {
    align-self: flex-start;
    text-align: left;
  }
  > :nth-child(even) {
    align-self: flex-end;
    text-align: right;
  }

  .text_box {
    z-index: 2;
    margin-bottom: 25px;
    max-width: 40em;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: 1180px) {
    .text_box {
      margin: 2rem;
      width: auto;
    }
  }
`;
