import React, { useEffect, useState } from "react";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import SideNav from "../Common/SideNav";
import { Myservice } from "../../interfaces";
import { MY_SERVICES_URL } from "../../constants/paths";
import Applications from "./Applications";
import placeholder_image from "../../images/placeholder-Veracity.png";
import Spinner from "../Common/Spinner";

function ApplicationsContainer() {
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingApps, setIsFetchingApps] = useState(true);
  const [userApplications, setUserApplications] = useState();
  const [filteredList, setFilteredList] = useState();
  const [apps, setApps] = useState();

  function getImageUrl(images: Array<any>, id: string) {
    let image = images.find(i => i.id === id);
    return image !== undefined ? image.imageUrl : placeholder_image;
  }
  function mapApplicationsData(apps: Array<Myservice>, images: Array<any>) {
    return apps.map(app => ({
      key: app.id,
      title: app.name,
      details: app.description,
      imageUrl: getImageUrl(images, app.id),
      onClick: () => {
        window.open(app.serviceUrl, "_blank");
      },
      imageOverlay: !!images.find(i => i.id === app.id)
    }));
  }

  useEffect(() => {
    fetch(MY_SERVICES_URL)
      .then(res => res.json())
      .then(res => {
        setApps(res);
        setIsFetchingApps(false);
      })
      .catch(err => {
        console.error("Oh no, error occured: ", err);
        setIsFetchingApps(false);
      });
  }, []);

  useEffect(() => {
    if (!isFetchingApps)
      fetch("akvaApplication/akvaApplications")
        .then((res: any) => res.json())
        .then(res => {
          let list = mapApplicationsData(apps, res);
          setUserApplications(list);
          setFilteredList(list);
          setIsLoading(false);
        })
        .catch(err => {
          console.error("Oh no, error occured: ", err);
          setIsLoading(false);
        });
  }, [!isFetchingApps]);

  return (
    <MainLayout>
      <SideNav
        className="side-nav"
        title="Applications"
        list={userApplications}
        updateList={setFilteredList}
      />
      <div className="main-content">
        {isFetchingApps || (isLoading && <Spinner />)}
        {userApplications && (
          <Applications
            isLoading={isLoading}
            userApplications={userApplications}
            filteredList={filteredList}
          />
        )}
      </div>
    </MainLayout>
  );
}

export default ApplicationsContainer;
