import React, { ReactNode, useEffect, useState } from "react";
import { ButtonPrimary, H1 } from "prisma-component-library";
import styled from "styled-components";
import { ButtonText } from "prisma-component-library";
import DivisionLine from "./DivisionLine";
import Search from "./Search";
import Select from "./Select";
import { filterByValue } from "../../utils/filterByValue";

interface IProps {
  list?: any[] | any;
  title: string;
  select?: ReactNode;
  PageSections?: string[];
  division?: string;
  className?: string;
  primaryAction?: () => void;
  primaryButtonName?: string;
  updateList?: { (value: any): void; (arg0: any[]): void } | any;
  previousLink?: ReactNode;
  additionalContent?: ReactNode;
}

function SideNav(props: IProps) {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", function(e) {
      if (window.scrollY > 57) {
        setIsScrolling(true);
      } else setIsScrolling(false);
    });
  }, []);

  return (
    <SidePanel className={props.className}>
      <SidePanelContent className={isScrolling ? "sticky_nav" : ""}>
        {props.previousLink && (
          <SidePanelPrevious>{props.previousLink}</SidePanelPrevious>
        )}
        <H1>{props.title}</H1>
        <SidePanelInteraction>
          {props.updateList && (
            <Search
              onChange={(e: any) =>
                filterByValue(props.list, e.target.value, props.updateList)
              }
            />
          )}
          {props.select && <Select />}
          {props.primaryAction && (
            <ButtonPrimary onClick={props.primaryAction}>
              {props.primaryButtonName}
            </ButtonPrimary>
          )}
        </SidePanelInteraction>
        <SidePanelNav>
          {props.division && <DivisionLine />}
          {props.PageSections &&
            props.PageSections.map(pageSection => (
              <ButtonText onClick={() => {}}>{pageSection}</ButtonText>
            ))}
        </SidePanelNav>
        {props.additionalContent && props.additionalContent}
      </SidePanelContent>
    </SidePanel>
  );
}
export default SideNav;

const SidePanel = styled.div`
  min-width: 15rem;
  min-height: 50vh;
  padding: 1rem 2.5rem;
  background-color: #fcfcfc;

  @media screen and (min-width: 680px) {
    .sticky_nav {
      position: fixed;
      top: 30px;
    }
  }

  @media screen and (max-width: 680px) {
    min-height: 0;
  }
`;

const SidePanelContent = styled.div`
  position: absolute;
  max-width: 15rem;

  @media screen and (max-width: 680px) {
    position: inherit;
    display: none;
    max-width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`;

const SidePanelPrevious = styled.div`
  a {
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: 680px) {
    a {
      margin-bottom: 0;
    }
  }
`;

const SidePanelInteraction = styled.div`
  display: flex;
  flex-direction: column;

  input,
  select,
  button {
    margin-top: 1rem;
  }
  @media screen and (max-width: 680px) {
    flex-direction: row;
    justify-content: space-between;
    input,
    select,
    button {
      margin-right: 0.5rem;
      margin-top: 0;
      flex: 1;
    }
  }
`;

const SidePanelNav = styled.div`
  padding: 0.5rem 0;

  @media screen and (max-width: 680px) {
    display: none;
  }
`;
