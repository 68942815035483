import React from "react";
import styled from "styled-components";
import { H3, Body } from "prisma-component-library";
const BlockContent = require("@sanity/block-content-to-react");

function ContentSections({ items }: any) {
  return (
    <DetailSectionsContainer>
      {items.map(
        (item: {
          text: any;
          image: { imageUrl: string; alt: string };
          heading: string;
        }) => {
          return (
            <DetailSection key={item.heading}>
              <div>
                <H3>{item.heading}</H3>
                <Body>
                  <BlockContent blocks={item.text} />
                </Body>
              </div>
              <div>
                <img src={item.image.imageUrl} alt={item.image.alt} />
              </div>
            </DetailSection>
          );
        }
      )}
    </DetailSectionsContainer>
  );
}

export default ContentSections;

const DetailSectionsContainer = styled.div`
  padding: 0 0 5rem;
`;

const DetailSection = styled.div`
  display: flex;
  margin-top: 40px;
  color: #0c2033;
  justify-content: space-between;

  :nth-child(even) {
    flex-direction: row-reverse;
  }

  > div {
    width: 48%;
  }

  p {
    margin: 16px 0;
  }

  img {
    width: 100%;
  }

  li {
    margin-left: 1rem;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    :nth-child(even) {
      flex-direction: column-reverse;
    }

    > div {
      width: 100%;
    }
  }
`;
