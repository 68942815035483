import React, { useContext } from "react";
import { Route, RouteProps } from "react-router";
import ProfileContext from "./Context";
import { LOG_IN } from "./constants/paths";

const ProtectedRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  const {
    isAuthorized,
    isAuthenticated,
    isLoading,
    hasCheckedAuthorization
  } = useContext(ProfileContext);

  const Component = component ? component : () => <></>;

  return (
    <Route
      {...rest}
      render={props => {
        if (!isLoading && !isAuthenticated) window.location.href = LOG_IN;
        else if (hasCheckedAuthorization && !isAuthorized)
          window.location.href = "err/unauthorized";
        if (isAuthorized) return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
