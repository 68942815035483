import React, { ReactNode } from "react";
import MainLayout from "../../../Layouts/MainLayout/MainLayout";
import styled from "styled-components";
import { Spinner, ImageOverlay } from "prisma-component-library";
import SideNav from "../SideNav";

interface ArticleProps {
  headerImage: string;
  altText: string;
  isLoading: boolean;
  title: string;
  primaryAction?: () => void;
  primaryButtonName?: string;
  children: ReactNode;
  previousLink?: ReactNode;
  navigationContent?: ReactNode;
}

function Article({
  children,
  isLoading,
  title,
  primaryAction,
  primaryButtonName,
  navigationContent,
  headerImage,
  altText,
  previousLink
}: ArticleProps) {
  return (
    <MainLayout>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <Container>
          <SideNav
            title={title}
            previousLink={previousLink}
            primaryAction={primaryAction}
            primaryButtonName={primaryButtonName}
            additionalContent={navigationContent}
          />

          <Content>
            <HeaderImageContainer>
              <HeaderImage src={headerImage} alt={altText} />
              <ImageOverlay />
            </HeaderImageContainer>
            <SubContent>{children}</SubContent>
          </Content>
        </Container>
      )}
    </MainLayout>
  );
}

export default Article;

const SpinnerContainer = styled.div`
  min-height: 200px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  background-color: #f2f3f5;
  display: flex;
  width: 100%;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 4rem;

  @media screen and (max-width: 952px) {
  }
`;

const HeaderImageContainer = styled.div`
  position: relative;
  display: flex;
  max-height: 24rem;
  margin-bottom: 2rem;
`;

const HeaderImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const SubContent = styled.div`
  max-width: 952px;
  margin: 0 auto;

  @media screen and (max-width: 1290px) {
    margin: 0 1rem;
  }
`;
