import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import errorPage from "../../images/errorpagesvg.svg";
import { ButtonPrimary, Spinner } from "prisma-component-library";
import { HOME } from "../../constants/paths";

function ErrorPage({ match }: any) {
  const [messageData, setMessageData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const defaultText =
    "We couldn’t find this page. Go back to the landing page by clicking here:";

  useEffect(() => {
    fetch("/message/error")
      .then((res: any) => res.json())
      .then(res => {
        const message = res.find(
          (messages: { title: any }) => messages.title === match.params.id
        );
        setMessageData(message);
        setIsLoading(false);
      })
      .catch(err => {
        setMessageData(defaultText);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <MainLayout>
      <Container>
        <img src={errorPage} />
        <Content>
          <ErrorText>
            {messageData ? messageData.body : <span>{defaultText}</span>}
          </ErrorText>
          <ButtonPrimary onClick={() => (window.location.href = HOME)}>
            Back to landing page
          </ButtonPrimary>
        </Content>
      </Container>
    </MainLayout>
  );
}

export default ErrorPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
`;

const ErrorText = styled.div`
  width: 100%;
  text-align: center;
  font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1600 - 320)));
  line-height: calc(32px + (40 - 32) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 2rem;
`;

const Content = styled.div`
  text-align: center;
  margin: auto;
  max-width: 55rem;
`;
