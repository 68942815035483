import React from "react";
import { ButtonSecondary, H2 } from "prisma-component-library";
import Modal from "../../Common/Modal";
import ModalPortal from "../../Common/ModalPortal";
import ProgressSteps from "../../Common/ProgressSteps";
import styled from "styled-components";
import { preLoadImage } from "../../../utils/preLoadImage";
const BlockContent = require("@sanity/block-content-to-react");

interface ModalProps {
  modalContent: any;
  modalOpen: boolean;
  selectedGuideStep: number;
  setSelectedGuideStep: (guideStep: number) => void;
  setModalOpen: (modalOpen: boolean) => void;
}

function GetStartedModal({
  modalContent,
  modalOpen,
  setModalOpen,
  selectedGuideStep,
  setSelectedGuideStep
}: ModalProps) {
  modalContent.map((content: { imageUrl: any }) =>
    preLoadImage(content.imageUrl)
  );

  const onClickNextStep =
    selectedGuideStep === modalContent.length - 1
      ? () => {
          setModalOpen(false);
          setSelectedGuideStep(0);
        }
      : () => setSelectedGuideStep(selectedGuideStep + 1);

  return (
    <ModalPortal display={modalOpen}>
      <Modal
        close={() => {
          setModalOpen(false);
          setSelectedGuideStep(0);
        }}
      >
        <ModalContent>
          <ModalImage>
            <img src={modalContent[selectedGuideStep].imageUrl} alt={""} />
          </ModalImage>
          {modalContent[selectedGuideStep].title && (
            <H2>{modalContent[selectedGuideStep].title}</H2>
          )}
          <BlockContent blocks={modalContent[selectedGuideStep].body} />
          <ButtonContainer>
            <ButtonSecondary onClick={() => onClickNextStep()}>
              {selectedGuideStep === modalContent.length - 1
                ? "Get Started"
                : "Next"}
            </ButtonSecondary>
          </ButtonContainer>
          <ProgressSteps
            length={modalContent.length}
            selectedStep={selectedGuideStep}
          />
        </ModalContent>
      </Modal>
    </ModalPortal>
  );
}

export default styled(GetStartedModal)`
  .text {
    color: pink !important;
  }
`;

const ModalImage = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  height: 16rem;
  width: 100%;
  overflow: hidden;
`;

const ModalContent = styled.div`
  h2 {
    margin: 14px 1.5rem 0;
  }
  strong {
    font-weight: 600;
  }
  p {
    margin: 14px 1.5rem 0;
  }

  li {
    margin-left: 3rem;
  }
`;

const ButtonContainer = styled.div`
  margin: 16px 0;
  display: flex;
  justify-content: center;
`;
