import React, { useState } from "react";
import { ButtonPrimary, Anchor } from "prisma-component-library";
import Cookies from "universal-cookie";
import styled from "styled-components";

function CookieConsent() {
  const cookies = new Cookies();
  const [consent, setConsent] = useState(cookies.get("consent"));
  const createCookie = () => {
    cookies.set("consent", true, { path: "/" });
    setConsent(true);
  };

  if (!consent) {
    return (
      <CookieConsentContainer>
        <span>
          PRISMA uses cookies to improve the website.&nbsp;
          <Anchor
            href="https://www.dnvgl.no/privacy/cookie-information.html"
            target="_new"
          >
            Read more about our policies and how to change your settings here
          </Anchor>
        </span>
        <ButtonPrimary onClick={createCookie}>OK</ButtonPrimary>
      </CookieConsentContainer>
    );
  } else return null;
}

export default CookieConsent;

const CookieConsentContainer = styled.div`
  top: 0;
  width: 100%;
  background: #fff;
  min-height: 100px;
  z-index: 7;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d5d7dd;
  position: sticky;

  span {
    max-width: 650px;
    text-align: center;
  }
  span,
  button {
    margin: 10px;
  }
`;
