import React from "react";
import styled from "styled-components";

interface StepsProps {
  length: number;
  selectedStep: number;
  onClick?: (index: any) => void;
}
function ProgressSteps({ length, selectedStep, onClick }: StepsProps) {
  let steps = [];

  for (var i = 0; i < length; i++) {
    steps.push(
      <Step
        id={i.toString()}
        onClick={onClick ? event => onClick(event) : () => null}
        selected={selectedStep === i}
      />
    );
  }
  return <StepsContainer>{steps}</StepsContainer>;
}

export default ProgressSteps;

const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Step = styled.button`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;

  border: none;
  padding: 0;
  margin: 0 5px;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? "#5E687D" : "#D5D7DD"};
`;
