import React from "react";
import { Footer } from "prisma-component-library";
import styled from "styled-components";
import IntroTextBoxes from "./IntroTextBoxes";
import Prism from "./Prism";
import Hero from "./Hero";
import CookieConsent from "../Common/CookieConsent/CookieConsent";
import SubscriptionsListAll from "../Subscriptions/SubscriptionsListAll";

function LandingPage() {
  return (
    <LandingPageContainer>
      <CookieConsent />
      <Prism />
      <Hero />
      <IntroTextBoxes />
      <div className="bottom_container">
        <SubscriptionsContainer>
          <SubscriptionsHeader>
            <div className="subscriptions_line" />
            <h2>Our subscription plans - coming soon!</h2>
            <div className="subscriptions_line-sub-title">
              PRISMA is currently available in a limited premium version. Get in
              touch to know more
            </div>
            <div></div>
          </SubscriptionsHeader>
          <SubscriptionsListAll />
        </SubscriptionsContainer>
        <Footer
          transparent={true}
          links={[
            <a
              key="contact"
              href="https://services.veracity.com/prisma/supportanonymous"
              target="_blank"
            >
              Contact us
            </a>
          ]}
        />
      </div>
    </LandingPageContainer>
  );
}

export default LandingPage;

const LandingPageContainer = styled.div`
  background: #e5e5e5;

  .bottom_container {
    position: relative;
    background: linear-gradient(176.68deg, #0c2033 14.73%, #06101a 100.26%);
  }
`;

const SubscriptionsContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
`;

const SubscriptionsHeader = styled.div`
  padding: 40px 0 0;
  max-width: 1136px;
  margin: 0px auto 32px;

  h2 {
    font-weight: 300;
    font-size: 32px;
  }

  & > div,
  & > h2 {
    color: #fff;
  }

  .subscriptions_line {
    border-bottom: 1px solid;
    width: 200px;
    margin-bottom: 16px;
  }
  .subscriptions_line-sub-title {
    margin-top: 15px;
  }

  @media (max-width: 400px) {
    .subscriptions_line {
      width: 50%;
    }
  }
`;
