import styled from "styled-components";
import searchIcon from "./Assets/icon-search.png";

export default styled.input.attrs({ type: "search" })`
  font-size: 0.875rem;
  color: #031029;
  line-height: 1.5rem;
  padding: 0.75rem 2.75rem 0.75rem 1rem;
  border-radius: 0;
  min-width: 12.5rem;
  cursor: pointer;
  display: inline-block;
  min-width: 12.5rem;
  border: 1px solid #d5d7dd;
  box-sizing: border-box;
  background-color: #fff;
  background-image: url(${searchIcon});
  background-repeat: no-repeat, repeat;
  background-position: right 0.75rem top 50%, 0 0;
  background-size: auto, 100%;
  transition: 0.2s;
  outline: none;

  :hover {
    box-shadow: 0px 0px 8px rgba(3, 16, 41, 0.2);
  }
  :focus {
    border: 1px solid #5653f2;
    box-shadow: 0px 0px 8px rgba(3, 16, 41, 0.2);
  }
`;
