import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { H3 } from "prisma-component-library";
import InfoList from "../Common/InfoList";
import ContentSections from "../Common/ContentSections";
import Quote from "./Quote";
import { PRODUCT_URL } from "../../constants/paths";
import Article from "../Common/Article/Article";

interface ProductProps {
  presentation: {
    name: string;
    detailSections: object;
    mainImage: {
      imageUrl: string;
      alt: string;
    };
    features: object[];
    testimonials: object;
  };
  provider: {
    organization: {
      alt: string;
      imageUrl: string;
      name: string;
      description: string;
    };
  };
}

function ProductDetailContainer({ match }: any) {
  const [productDetail, setProductDetail] = useState<ProductProps>({
    presentation: {
      name: "",
      detailSections: {},
      mainImage: {
        imageUrl: "",
        alt: ""
      },
      features: [],
      testimonials: {}
    },
    provider: {
      organization: {
        alt: "",
        imageUrl: "",
        name: "",
        description: ""
      }
    }
  });
  const [akvaApplications, setAkvaApplications] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingApps, setIsFetchingApps] = useState(true);

  useEffect(() => {
    fetch(`${PRODUCT_URL}${match.params.productHeadId}`)
      .then(res => res.json())
      .then(res => {
        setProductDetail(res);
        setIsFetchingApps(false);
      })
      .catch(error => {
        console.error("Unable to update item.", error);
        setIsLoading(false);
      });
  }, [match.params.productHeadId]);

  useEffect(() => {
    if (!isFetchingApps)
      fetch(`/akvaApplication/${match.params.productHeadId}`)
        .then((res: any) => res.json())
        .then(res => {
          setAkvaApplications(res);
          setIsLoading(false);
        })
        .catch(err => {
          console.error("Oh no, error occured: ", err);
          setIsLoading(false);
        });
  }, [isFetchingApps]);

  function mapFeatures(features: any) {
    return features.map((feature: { image: any; icon: any; imageUrl: any }) => {
      return {
        ...feature,
        image: {
          ...feature.image,
          imageUrl: feature.icon ? feature.icon.imageUrl : feature.imageUrl
        }
      };
    });
  }

  function UpdateContactInformation(title: string, id: string) {
    if (
      id === "331fce80-75dc-4b03-99ad-56de9577ae8d" ||
      id === "87dba861-bbe6-46f0-84c2-c2621d07bca0"
    )
      return `${akvaApplications.contact}`;
    else {
      return `mailto:${akvaApplications.contact}?subject=AquaHub - ${title}&body=`;
    }
  }

  return (
    <Article
      isLoading={isLoading || isFetchingApps}
      title={productDetail.presentation.name}
      primaryAction={() =>
        window.open(
          UpdateContactInformation(
            productDetail.presentation.name,
            match.params.productHeadId
          )
        )
      }
      primaryButtonName={
        akvaApplications ? akvaApplications.contactInformation : ""
      }
      headerImage={productDetail.presentation.mainImage.imageUrl}
      altText={productDetail.presentation.mainImage.alt}
    >
      <>
        <InfoList items={mapFeatures(productDetail.presentation.features)} />
        <ContentSections items={productDetail.presentation.detailSections} />
        {productDetail.presentation.testimonials && (
          <Quote quotes={productDetail.presentation.testimonials} />
        )}
        <ProviderContainer>
          <img
            alt={productDetail.provider.organization.alt}
            src={productDetail.provider.organization.imageUrl}
          />

          <div>
            <H3>{productDetail.provider.organization.name}</H3>
            <span>{productDetail.provider.organization.description}</span>
          </div>
        </ProviderContainer>
      </>
    </Article>
  );
}

export default ProductDetailContainer;

const ProviderContainer = styled.div`
  display: flex;
  margin: 40px 0 80px;
  padding-top: 40px;
  border-top: 1px solid #959dad;

  img {
    max-width: 175px;
    width: 100%;
    height: 100%;
  }

  div {
    margin-left: 40px;

    h3 {
      margin-bottom: 1rem;
    }
  }
`;
