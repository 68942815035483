import React from "react";
import styled from "styled-components";

const InfoList: React.FC<any> = ({ items }: any) => {
  return (
    <InfoListContainer>
      {items.map(
        (item: {
          image: { imageUrl: string; alt: string };
          description: string;
        }) => {
          return (
            <div key={item.description}>
              {item.image && (
                <img src={item.image.imageUrl} alt={item.image.alt} />
              )}
              <span>{item.description}</span>
            </div>
          );
        }
      )}
    </InfoListContainer>
  );
};

export default InfoList;

const InfoListContainer = styled.div`
  display: flex;
  min-height: 184px;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #959dad;
  align-items: center;
  padding-top: 24px;

  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 317px;
  }
  img {
    height: 48px;
    filter: brightness(0%);
  }

  span {
    text-align: center;
    margin: 24px;
  }
`;
