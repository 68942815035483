import React from "react";
import styled from "styled-components";
import { CardItem } from "prisma-component-library";
import { cardDataInterface } from "../../../interfaces";

type IProps = {
  items: cardDataInterface[];
};

function CardGenericContainer({ items }: IProps) {
  return (
    <Container>
      {items.map(item => (
        <CardItem
          title={item.title}
          image={item.imageUrl}
          alt={item.alt ? item.alt : item.title}
          key={item.title}
          onClick={item.onClick}
          imageOverlay={item.imageOverlay}
          description={item.details}
          logoUrl={item.logoUrl}
        />
      ))}
    </Container>
  );
}

export default CardGenericContainer;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
