import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Spinner from "../Common/Spinner";
import CardGenericContainer from "../Common/Cards/CardGenericContainer";
import { cardDataInterface } from "../../interfaces";

interface ApplicationsIP {
  isLoading: boolean;
  userApplications: cardDataInterface[];
  filteredList: cardDataInterface[];
}
const Applications = ({
  isLoading,
  userApplications,
  filteredList
}: ApplicationsIP) => {
  return (
    <div>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : userApplications.length > 0 ? (
        <CardGenericContainer items={filteredList} />
      ) : (
        <NavLink to="/marketplace">
          You do not have access to any of the applications. Click here to visit
          the marketplace
        </NavLink>
      )}
    </div>
  );
};

export default Applications;

const SpinnerContainer = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
