export function filterByValue(
  array: any[],
  string: string,
  update: { (value: any): void; (arg0: any[]): void }
) {
  const filteredList = array.filter(function(o) {
    return Object.keys(o).some(function(k) {
      return (
        o[k]
          .toString()
          .toLowerCase()
          .indexOf(string.toLowerCase()) !== -1
      );
    });
  });
  update(filteredList);
}
