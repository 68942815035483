import React from "react";
import { LargeCard } from "prisma-component-library";
import styled from "styled-components";
import DashBoard1 from "../../images/Graph1.png";
import DashBoard2 from "../../images/Graph2.png";
import DashBoard3 from "../../images/Graph3.png";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import GetStartedContainer from "./GetStartedGuide/GetStartedContainer";
import NewsCard from "./NewsCard";

const Dashboard: React.FC = () => {
  return (
    <MainLayout>
      <Container>
        <TopContainers>
          <GetStartedContainer />
          <NewsCard />
        </TopContainers>
        <BottomContainers>
          <LargeCard
            imageUrl={DashBoard1}
            altText="Graph showing index and price of salmon"
          />
          <LargeCard
            imageUrl={DashBoard2}
            altText="Atlantic salmon index numbers"
          />
          <LargeCard
            imageUrl={DashBoard3}
            altText="PRISMA overview of members, containers and applications data "
          />
        </BottomContainers>
      </Container>
    </MainLayout>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1048px;
  margin: 0 auto 2rem auto;
`;

const TopContainers = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1048px) {
    justify-content: center;
  }
`;
const BottomContainers = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1048px) {
    justify-content: center;
  }
`;
