import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import MyData from "./Components/MyData/MyData";
import Marketplace from "./Components/Marketplace/Marketplace";
import LandingPage from "./Components/LandingPage/LandingPage";
import Profile from "./Components/Profile/ProfileContainer";
import ProtectedRoute from "./ProtectedRoute";
import "prisma-component-library/dist/style.css";
import AuthHandler from "./Components/Common/AuthHandler";

import {
  HOME,
  DASHBOARD,
  APPLICATIONS,
  MY_DATA,
  MARKETPLACE,
  PROFILE
} from "./constants/paths";
import ApplicationsContainer from "./Components/Applications/ApplicationsContainer";
import ProductDetailContainer from "./Components/ProductDetail/ProductDetailContainer";
import { ERROR, SUBSCRIPTIONS, NEWS_ARTICLE } from "./constants/paths";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import SubscriptionsPageContainer from "./Components/Subscriptions/SubscriptionsPageContainer";
import NewsContainer from "./Components/News/NewsContainer";

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <AuthHandler>
            <Route exact path={HOME} component={LandingPage} />
            <Route path={ERROR} component={ErrorPage} />
            <Route
              path={SUBSCRIPTIONS}
              component={SubscriptionsPageContainer}
            />
            <Route exact path={MARKETPLACE} component={Marketplace} />
            <Route
              exact
              path={"/marketplace/:productHeadId"}
              component={ProductDetailContainer}
            />
            <ProtectedRoute exact path={DASHBOARD} component={Dashboard} />
            <ProtectedRoute
              exact
              path={APPLICATIONS}
              component={ApplicationsContainer}
            />
            <ProtectedRoute exact path={MY_DATA} component={MyData} />
            <ProtectedRoute exact path={PROFILE} component={Profile} />
            <ProtectedRoute
              exact
              path={NEWS_ARTICLE}
              component={NewsContainer}
            />
          </AuthHandler>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
