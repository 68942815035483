import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { TopBar, Spinner } from "prisma-component-library";
import { landingPageLinks } from "../../constants/navigationLinks";
import SubscriptionsPageContent from "./SubscriptionsPageContent";
import SubscriptionsListAll from "./SubscriptionsListAll";
import useSubscriptionTypes from "../../utils/customHooks/useSubscriptionTypes";
import useMarketplaceData from "../../utils/customHooks/useMarketplaceData";

function SubscriptionsPageContainer({ match }: any) {
  const subscriptionData = useSubscriptionTypes();
  const marketplaceData = useMarketplaceData();

  const date = new Date();
  const year = date.getFullYear();
  const history = useHistory();

  if (subscriptionData && marketplaceData) {
    const menuOnClick = (item: any) => {
      history.push(item.item);
    };

    return (
      <div>
        <TopBar
          showLogo
          transparent={true}
          menuOnClick={menuOnClick}
          navigationLinks={landingPageLinks}
        />
        {match.params.id ? (
          <SubscriptionsPageContent
            subscriptionData={subscriptionData}
            marketplaceData={marketplaceData}
            id={match.params.id}
          />
        ) : (
          <Container>
            <h2>Subscription plans</h2>
            <SubscriptionsListAll />
          </Container>
        )}
        <Footer>
          <span>Copyright {year} Prism</span> |
          <a key="contact" href="mailto:sgylterud@akvagroup.com">
            Contact us
          </a>
        </Footer>
      </div>
    );
  }
  return <Spinner />;
}

export default SubscriptionsPageContainer;

const Container = styled.div`
  margin: 2.5rem auto;
  max-width: 1024px;

  h2 {
    font-weight: 300;
    font-size: 32px;
    line-height: 88px;
  }
`;

const Footer = styled.div`
  height: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span,
  a {
    margin: 0 0.5rem;
  }
`;
