import React, { useState, useEffect } from "react";
import { LargeCard, ButtonPrimary, H3 } from "prisma-component-library";
import styled from "styled-components";
import GetStartedModal from "./GetStartedModal";
const BlockContent = require("@sanity/block-content-to-react");

function GetStartedContainer() {
  const [dashboardCardData, setDashboardCardData] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [modalContent, setModalContent] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGuideStep, setSelectedGuideStep] = useState(0);

  useEffect(() => {
    fetch("guide/guides")
      .then((res: any) => res.json())
      .then(data => {
        setDashboardCardData(data.find((data: any) => data.priority === 0));
        setModalContent(data.filter((data: any) => data.priority !== 0));
        setIsFetching(false);
      })
      .catch((err: any) => {
        console.error("Oh no, error occured: ", err);
      });
  }, []);

  if (dashboardCardData) {
    return (
      <LargeCard
        imageUrl={dashboardCardData ? dashboardCardData.imageUrl : ""}
        displayOverlay={true}
      >
        {dashboardCardData && modalContent && (
          <Content>
            <H3>{dashboardCardData.title}</H3>
            <BlockContent blocks={dashboardCardData.body} />

            <ButtonPrimary onClick={() => setModalOpen(true)}>
              Get Started
            </ButtonPrimary>
            <GetStartedModal
              modalOpen={modalOpen}
              modalContent={modalContent}
              selectedGuideStep={selectedGuideStep}
              setSelectedGuideStep={setSelectedGuideStep}
              setModalOpen={setModalOpen}
            />
          </Content>
        )}
      </LargeCard>
    );
  } else return null;
}
export default GetStartedContainer;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    flex-grow: 1;
  }
`;
