import React, { useContext } from "react";
import { TopBar, Footer } from "prisma-component-library";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Context from "../../Context";
import { navigationItems } from "./navigationItems";
import { footerLinks } from "./footerLinks";
import { LOG_OUT } from "../../constants/paths";

function MainLayout(props: { children: any }) {
  const { children } = props;
  const { userData } = useContext(Context);
  const history = useHistory();

  const initials = userData
    ? `${userData.firstName.charAt(0).toUpperCase()}${userData.lastName
        .charAt(0)
        .toUpperCase()}`
    : "";

  const menuOnClick = (item: any) => {
    if (item.item === LOG_OUT) {
      window.location = item.item;
    } else {
      history.push(item.item);
    }
  };

  return (
    <React.Fragment>
      <TopBar
        avatarInitials={initials}
        navigationLinks={navigationItems}
        menuOnClick={menuOnClick}
        showLogo={true}
        showProfile
      />
      <MainContainer>{children}</MainContainer>
      <Footer links={footerLinks} />
    </React.Fragment>
  );
}

export default MainLayout;

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;
