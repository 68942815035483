export type Result<T> = Ok<T> | Error;

export interface Ok<T> {
  value: T;
}
export interface Error {
  statusCode: number;
  message: string;
}

export default async function generalFetch<T>(
  url: string,
  allowedResultCodes?: number[]
): Promise<Result<T> | Error> {
  try {
    const response = await fetch(url);
    if (
      allowedResultCodes
        ? !allowedResultCodes.includes(response.status)
        : response.status >= 300
    )
      return getError(response.status, await response.json());

    const data = await response.json();
    return getResult(data.value !== undefined ? data.value : data);
  } catch (error) {
    return getError(500, error.message);
  }
}

export function isOk<T>(response: Result<T>): response is Ok<T> {
  return (response as Ok<T>).value !== undefined;
}

function getResult<T>(value: T): Result<T> {
  return { value };
}

function getError(statusCode: number, message: string): Error {
  return { statusCode, message };
}
