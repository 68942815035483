import React from "react";
import styled, { keyframes } from "styled-components";

function Prism() {
  return (
    <PrismContainer>
      <TransformedPrism>
        <PrismFront />
        <PrismBottom />
        <PrismBack />
      </TransformedPrism>
    </PrismContainer>
  );
}

export default Prism;

const rotate = keyframes`
  0% { transform: rotate3d(1, 1, 1, 345deg);}
  20% { transform: rotate3d(0.5, 1, 0, 270deg);}
  40% { transform: rotate3d(0.1, 0.9, 0.3, 180deg);}
  60% { transform: rotate3d(0.1, 0.9, 0.3, 90deg);}
  80% { transform: rotate3d(1, 1, 1, 45deg);}
  100% { transform: rotate3d(1, 1, 1, 0deg);}
`;

const PrismContainer = styled.div`
  position: fixed;
  transform: scale(2.7);
  right: 200px;
`;

const TransformedPrism = styled.div`
  transform-style: preserve-3d;
  transform: rotate3d(0.2, 0.7, 0.5, 211deg);

  > div {
    position: absolute;
    height: 298px;
    background-color: transparent;
    color: gray;
    text-align: center;
    line-height: 200px;
  }

  @media only screen and (min-width: 1024px) {
    animation: ${rotate} 300s linear infinite;
  }
`;

const PrismFront = styled.div`
  width: 150px;
  transform-origin: 50% 100%;
  transform: translateZ(200px) rotateX(30deg);
  border: 1px solid;
  transition: opacity 0.5s ease;
  border-image: linear-gradient(#f30a47, #8f00ff) 1;
`;

const PrismBack = styled.div`
  width: 150px;
  transform-origin: 50% 100%;
  transform: translateZ(-100px) rotateY(180deg) rotateX(30deg);
  border: 1px solid;
  transition: opacity 0.5s ease;
  border-image: linear-gradient(#f30a47, #8f00ff) 1;
`;

const PrismBottom = styled.div`
  width: 150px;
  transform: rotateX(270deg) translateY(100px);
  transform-origin: bottom center;
  border: 1px solid;
  transition: opacity 0.5s ease;
  border-image: linear-gradient(#8f00ff, #8f00ff) 1;
`;
