import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { H1, Body } from "prisma-component-library";
const BlockContent = require("@sanity/block-content-to-react");

interface IProps {
  body: object;
  description?: string;
  imageUrl: string;
  name: string;
}
function SubscriptionCard({ body, description, imageUrl, name }: IProps) {
  return (
    <SubscriptionCardContainer to={"/subscriptions/" + name.toLowerCase()}>
      <SubscriptionCardImage
        src={imageUrl}
        alt={`Representing the type of subscription for ${name}`}
      />
      <H1>{name}</H1>
      {description && (
        <SubscriptionCardDescription>
          <Body>{description}</Body>
        </SubscriptionCardDescription>
      )}
      <SubscriptionCardContent>
        <BlockContent blocks={body} />
      </SubscriptionCardContent>
    </SubscriptionCardContainer>
  );
}

export default SubscriptionCard;

const SubscriptionCardContainer = styled(NavLink)`
  padding: 0;
  border: none;
  max-width: 328px;
  background: #ffffff;
  padding-bottom: 50px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin-bottom: 1rem;

  h1 {
    margin: 25px 0;
  }

  ul {
    list-style: none;
  }

  ul li:before {
    content: "✓";
    position: absolute;
    left: -18px;
  }

  li {
    position: relative;
    margin-left: 10px;
    margin-bottom: 16px;
  }
  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.24);
    -webkit-text-fill-color: #0c2033;
    text-decoration: none;
    background: #ffffff;
    -webkit-background-clip: unset;

    span {
      color: #f03a47;
      -webkit-text-fill-color: #f03a47;
    }
  }
`;

const SubscriptionCardImage = styled.img`
  max-width: 328px;
  max-height: 246px;
  height: auto;
  width: 100%;
  border-radius: 2px;
`;

const SubscriptionCardContent = styled.div`
  margin: 0 30px 50px;
`;

const SubscriptionCardDescription = styled.div`
  border: 1px solid #f03a47;
  color: #f03a47;
  width: 148px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
`;
