import React, { useState } from "react";
import ProfileContext, { UserData } from "../../Context";
import fetch, { isOk, Result, Error } from "../../utils/fetchWrapper";
import useAsyncEffect from "../../utils/customHooks/useAsyncEffect";
import {
  AUTHENTICATION_URL,
  AUTHORIZATION_URL,
  MY_PROFILE_URL
} from "../../constants/paths";
const AuthHandler: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthorized, setAuthorized] = useState(false);
  const [userData, setUserData] = useState<UserData | undefined>();
  const [isLoading, setLoading] = useState(true);
  const [hasCheckedAuthorization, setCheckedAuthorization] = useState(false);

  useAsyncEffect(async () => {
    const response = await fetch<boolean>(AUTHENTICATION_URL, [200]);
    let failed = false;
    if (isOk(response)) {
      if (isAuthenticated !== response.value) setAuthenticated(response.value);
    } else {
      console.error(response.statusCode, response.message);
      failed = true;
    }

    if (isLoading) setLoading(false);

    if (failed) {
      window.location.href = "err/unauthorized"; //TODO: this is actually unAUTHENTICATED not unAUTHORIZED
    }
  });

  useAsyncEffect(async () => {
    if (isAuthenticated) {
      const reqs: [
        Promise<Result<any> | Error>,
        Promise<Result<UserData> | Error>
      ] = [fetch<boolean>(AUTHORIZATION_URL), fetch<UserData>(MY_PROFILE_URL)];
      const [authorizedResponse, profileResponse] = await Promise.all(reqs);
      if (isOk(authorizedResponse)) {
        if (!isAuthorized) setAuthorized(true);
      } else {
        if (isAuthorized) setAuthorized(false);
      }

      if (isOk(profileResponse)) setUserData(profileResponse.value);
      else {
        if (!userData) setUserData(undefined);
      }

      setCheckedAuthorization(true);
    }
  }, [isAuthenticated]);

  return (
    <ProfileContext.Provider
      value={{
        userData,
        isLoading,
        isAuthorized,
        isAuthenticated,
        hasCheckedAuthorization
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default AuthHandler;
