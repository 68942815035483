import React from "react";
import { ButtonPrimary } from "prisma-component-library";
import styled from "styled-components";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import mydataImages from "../../images/mydata.png";
import { VERACITY_MYDATA_URL } from "../../constants/paths";

function Containers() {
  return (
    <MainLayout>
      <DataContainer>
        <InfoContainer>
          This page will be ready soon! Meanwhile, you can administrate your
          data directly in Veracity
          <ButtonPrimary
            onClick={() => {
              window.open(VERACITY_MYDATA_URL, "_blank");
            }}
          >
            Go to veracity
          </ButtonPrimary>
        </InfoContainer>
        <MydataImage alt="Mydata blurry image" src={mydataImages} />
      </DataContainer>
    </MainLayout>
  );
}

export default Containers;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #f2f3f5;
  padding: 1rem 0rem 1rem 0rem;
  border-bottom: 1px solid #d5d7dd;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.24);

  align-items: center;

  button {
    margin-left: 1rem;
  }
`;
const MydataImage = styled.img`
  width: 100%;
`;
