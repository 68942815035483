import React, { useEffect, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import styled from "styled-components";
import { NEWS, ERROR_PATH, DASHBOARD } from "../../constants/paths";
import Article from "../Common/Article/Article";
import { Number } from "prisma-component-library";
const BlockContent = require("@sanity/block-content-to-react");

interface NewsPreviewProps {
  _id: string;
  imageUrl: string;
  title: string;
  length: number;
  text: object;
  preamble: string;
}

function NewsContainer({ match }: any) {
  const [selectedArticle, setSelectedArticle] = useState<NewsPreviewProps>();
  const [articleData, setArticleData] = useState<NewsPreviewProps[]>();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetch(NEWS)
      .then((res: any) => res.json())
      .then(data => {
        const findArticle = data.find(
          (article: NewsPreviewProps) => article._id === match.params.id
        );
        if (findArticle) {
          setSelectedArticle(findArticle);
          setArticleData(data);
          setIsLoading(false);
        } else {
          throw Error;
        }
      })
      .catch((err: any) => {
        history.push(ERROR_PATH);
      });
  }, [match.params.id]);

  const navigationContent = (
    <NavigationContent>
      <hr />
      {articleData
        ? articleData.map(article => (
            <NavLink to={`/article/${article._id}`}>{article.title}</NavLink>
          ))
        : null}
    </NavigationContent>
  );

  return (
    <Article
      isLoading={isLoading}
      title={"News articles"}
      headerImage={selectedArticle ? selectedArticle.imageUrl : ""}
      altText={"alt text"}
      previousLink={<NavLink to={DASHBOARD}> &lt; Back to dashboard</NavLink>}
      navigationContent={navigationContent}
    >
      <Content>
        {selectedArticle && <Number>{selectedArticle.title}</Number>}
        {selectedArticle && selectedArticle.preamble && (
          <h2>{selectedArticle.preamble}</h2>
        )}
        {selectedArticle && <BlockContent blocks={selectedArticle.text} />}
      </Content>
    </Article>
  );
}

export default NewsContainer;

const Content = styled.div`
  max-width: 722px;

  p {
    margin: 1.5rem 0;
  }

  h2,
  img {
    margin: 1rem 0;
  }

  ul {
    margin: 0.5rem 0;
  }

  li {
    list-style-position: inside;
  }

  img {
    max-width: 100%;
  }
`;

const NavigationContent = styled.div`
  a {
    margin: 0.5rem 0 0.5rem 0.5rem;
    text-decoration: none;
    max-width: 15rem;
  }

  hr {
    width: 15rem;
    margin-left: 0;
  }
  .active {
    font-weight: 400;
  }

  @media screen and (max-width: 680px) {
    display: none;
  }
`;
