import { useState, useEffect } from "react";
import { SUBSCRIPTION_TYPES } from "../../constants/paths";

function compare(a: { priority: number }, b: { priority: number }) {
  if (a.priority > b.priority) {
    return -1;
  } else if (a.priority < b.priority) {
    return 1;
  }
}

function useSubscriptionTypes() {
  const [subscriptionData, setSubscriptionData] = useState();
  useEffect(() => {
    fetch(SUBSCRIPTION_TYPES)
      .then((res: any) => res.json())
      .then(data => {
        const sortedByPriority = data.sort(compare);
        setSubscriptionData(sortedByPriority);
      })
      .catch((err: any) => {
        console.error("Oh no, error occured: ", err);
      });
  }, []);

  return subscriptionData;
}

export default useSubscriptionTypes;
