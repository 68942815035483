import { createContext } from "react";

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  phoneNumber: number;
  companyName: string;
}

export interface Profile {
  userData?: UserData;
  isLoading: boolean;
  isAuthenticated: boolean;
  isAuthorized: boolean;
  hasCheckedAuthorization: boolean;
}

export const profileInitialState: Profile = {
  userData: undefined,
  isLoading: false,
  isAuthenticated: false,
  isAuthorized: false,
  hasCheckedAuthorization: false
};

const ProfileContext = createContext(profileInitialState);

export default ProfileContext;
