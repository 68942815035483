import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import SideNav from "../Common/SideNav";
import CardGenericContainer from "../Common/Cards/CardGenericContainer";
import { PRODUCTS_URL } from "../../constants/paths";
import Spinner from "../Common/Spinner";
import { mapApplications } from "../../utils/mapApplications";

function Marketplace() {
  const [applicationData, setApplicationData] = useState();
  const [filteredList, setFilteredList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetch(PRODUCTS_URL)
      .then(res => res.json())
      .then(res => {
        setFilteredList(res.products);
        setApplicationData(res.products);
        setIsLoading(false);
      })
      .catch(err => {
        console.error("Oh no, error occured: ", err);
        setIsLoading(false);
      });
  }, []);

  return (
    <MainLayout>
      <SideNav
        className="side-nav"
        title="Marketplace"
        list={applicationData}
        updateList={setFilteredList}
      />
      <div className="main-content">
        {isLoading && <Spinner />}
        {applicationData && (
          <CardGenericContainer
            items={mapApplications(filteredList, history)}
          />
        )}
      </div>
    </MainLayout>
  );
}

export default Marketplace;
