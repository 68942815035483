import React from "react";
import styled from "styled-components";

import { useHistory } from "react-router-dom";
import {
  ButtonPrimary,
  ButtonSecondary,
  Number,
  Logo,
  TopBar
} from "prisma-component-library";
import { landingPageLinks } from "../../constants/navigationLinks";

function Hero() {
  const history = useHistory();
  const menuOnClick = (item: any) => {
    history.push(item.item);
  };

  return (
    <HeroContainer>
      <div className="hero_top_menu">
        <TopBar
          transparent
          menuOnClick={menuOnClick}
          navigationLinks={landingPageLinks}
          light
        />
      </div>
      <video id="hero_video" preload="true" autoPlay loop muted playsInline>
        <source src="/video/HERO.mp4" type="video/mp4" />
      </video>
      <HeroContent>
        <div>
          <div className="hero_logo">
            <Logo />
          </div>
          <Number>We connect nature, technology and people</Number>
          <div className="buttons">
            <ButtonPrimary
              onClick={() => (window.location.href = "/dashboard")}
            >
              Log in
            </ButtonPrimary>
            <ButtonSecondary
              light={true}
              onClick={() =>
                window.open(
                  "mailto:sgylterud@akvagroup.com?subject=PRISM&body="
                )
              }
            >
              Contact us
            </ButtonSecondary>
          </div>
        </div>
      </HeroContent>
    </HeroContainer>
  );
}

export default Hero;

const HeroContainer = styled.div`
  background: linear-gradient(144.7deg, #8f00ff -5.64%, #0c2033 66.82%);
  mix-blend-mode: normal;
  min-height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .hero_top_menu {
    position: absolute;
    width: 100%;
    z-index: 3;
    top: 0;
  }

  .buttons {
    margin-top: 2rem;
    button {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .hero-login {
    position: absolute;
    right: 0;
    top: 0;
  }

  #hero_video {
    object-fit: cover;
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 768px;
  }

  .hero_logo {
    svg {
      height: auto;
      max-width: 358px;
      width: 100%;
      margin-bottom: 8px;
    }
  }
  @media (min-width: 1920px) {
    #hero_video {
      height: auto;
      width: 100%;
    }
  }
`;

const HeroContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  width: 100%;
  color: #fff;

  & > div {
    max-width: 1136px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;

    & > span {
      max-width: 820px;
    }
  }

  @media screen and (max-width: 1180px) {
    & > div {
      margin: 0 2rem;
      width: auto;
    }
  }
`;
