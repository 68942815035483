import { cardDataInterface } from "../interfaces";

export function mapApplications(apps: Array<cardDataInterface>, history: any) {
  return apps.map(app => {
    return {
      ...app,
      onClick: () => history.push(`/marketplace/${app._id}`),
      imageOverlay: true,
      logoUrl: app.provider.organization.imageUrl
    };
  });
}
