import React, { useEffect, useRef } from "react";
import styled from "styled-components";

/* Empty centered modal with functionality to 
  close when user clicks outside the frame */

interface IProps {
  children: object;
  close: () => void;
}
const Modal = (props: IProps) => {
  const wrapperRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  });

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.close();
    }
  };

  return (
    <ModalBackground>
      <ModalFrame ref={wrapperRef}>
        <ModalContent>{props.children}</ModalContent>
      </ModalFrame>
    </ModalBackground>
  );
};
export default Modal;
const ModalBackground = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(24, 28, 62, 0.5);
`;
const ModalFrame = styled.div`
  background-color: #fff;
  border-radius: 3px;
  max-width: 34rem;
  margin: 0 auto;
  margin-top: 5rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  min-height: 12rem;
  justify-content: space-between;
  width: auto;

  button {
    align-self: center;
  }
`;
