export const HOME = "/";
export const DASHBOARD = "/dashboard";
export const APPLICATIONS = "/applications";
export const MY_DATA = "https://mydata.prisma.no/";
export const MARKETPLACE = "/marketplace";
export const PROFILE = "/profile";
export const SUBSCRIPTIONS_PATH = "/subscriptions";
export const ERROR_PATH = "/err";

export const LOG_OUT = "/account/logout";
export const LOG_IN = "/account/login";
export const ERROR = "/err/:id?";
export const SUBSCRIPTIONS = "/subscriptions/:id?";
export const NEWS_ARTICLE = "/article/:id";

//Veracity service API endpoints
export const MY_SERVICES_URL = "/my/services";
export const MY_PROFILE_URL = "/my/profile";
export const AUTHENTICATION_URL = "/account/IsAuthenticated";
export const AUTHORIZATION_URL = "/account/IsAuthorized";
export const PRODUCT_URL = "/product/";
export const PRODUCTS_URL = "/product/products";
export const VERACITY_MYDATA_URL = "https://data.veracity.com/";

// Prisma API endpoints
export const SUBSCRIPTION_TYPES = "/landingpage/subscriptions";
export const NEWS = "/news";
export const NEWS_PREVIEW = "/news/preview";

export const PLACEHOLDER_IMAGE =
  "https://images.unsplash.com/40/rqZBrx1WRsCtkqGSVBK6_IMG_0063.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60";

export const AI_INSTRUMENTATION_KEY = "d1afd63b-ca2c-4234-af79-6424683442a0";
