import React from "react";
import styled from "styled-components";
import downArrow from "./Assets/icon-downarrow.png";

interface IProps {
  SelectOptions?: string[];
}

const Select = (props: IProps) => {
  return (
    <SelectFrame>
      {props.SelectOptions &&
        props.SelectOptions.map(selectOption => (
          <SelectOption>{selectOption}</SelectOption>
        ))}
    </SelectFrame>
  );
};
const SelectFrame = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  font-size: 0.875rem;
  color: #031029;
  line-height: 1.5rem;
  padding: 0.75rem 2.75rem 0.75rem 1rem;
  border-radius: 0;
  min-width: 12.5rem;

  box-sizing: border-box;
  border: 1px solid #d5d7dd;
  background-color: #fff;
  background-image: url(${downArrow});
  background-repeat: no-repeat, repeat;
  background-position: right 0.75rem top 50%, 0 0;
  background-size: auto, 100%;
  transition: 0.2s;
  outline: none;

  :hover {
    box-shadow: 0px 0px 8px rgba(3, 16, 41, 0.2);
  }

  :focus {
    border: 1px solid #5653f2;
    box-shadow: 0px 0px 8px rgba(3, 16, 41, 0.2);
  }
  ::-ms-expand {
    display: none;
  }
`;

const SelectOption = styled.option`
  font-size: 0.875rem;
  color: #031029;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
`;

export default Select;
