import React from "react";
import { NavLink } from "react-router-dom";
import {
  DASHBOARD,
  APPLICATIONS,
  MY_DATA,
  MARKETPLACE,
  PROFILE,
  LOG_OUT
} from "../../constants/paths";

export const navigationItems = [
  {
    component: (
      <NavLink to={DASHBOARD} key="Dashboard">
        Dashboard
      </NavLink>
    ),
    type: "main" as const,
    name: "Dashboard",
    item: DASHBOARD
  },
  {
    component: (
      <NavLink to={APPLICATIONS} key="applications">
        Applications
      </NavLink>
    ),
    type: "main" as const,
    name: "Applications",
    item: APPLICATIONS
  },
  {
    component: (
     <a href={MY_DATA}>My data</a>
    ),
    type: "main" as const,
    name: "My Data",
    item: MY_DATA
  },
  {
    component: (
      <NavLink to={MARKETPLACE} key="marketplace">
        Marketplace
      </NavLink>
    ),
    type: "main" as const,
    name: "Marketplace",
    item: MARKETPLACE
  },
  {
    component: (
      <NavLink to={PROFILE} key="profile">
        Profile
      </NavLink>
    ),
    type: "profile" as const,
    item: PROFILE,
    name: "Profile"
  },
  {
    component: (
      <a href={LOG_OUT} key="logout">
        Log out
      </a>
    ),
    type: "profile" as const,
    item: LOG_OUT,
    name: "Log out"
  }
];
