import { useState, useEffect } from "react";
import { PRODUCTS_URL } from "../../constants/paths";

function useMarketplaceData() {
  const [marketplaceData, setMarketplaceData] = useState();

  useEffect(() => {
    fetch(PRODUCTS_URL)
      .then(res => res.json())
      .then(res => {
        setMarketplaceData(res.products);
      })
      .catch(err => {
        console.error("Oh no, error occured: ", err);
      });
  }, []);

  return marketplaceData;
}

export default useMarketplaceData;
