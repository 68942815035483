import { createPortal } from "react-dom";

/* Creates an empty DOM node "modal-root" outside the 
DOM hierarchy of the parent component */

interface PortalProps {
  display: boolean;
  children: object;
}
function ModalPortal({ display, children }: PortalProps) {
  if (display) {
    return createPortal(children, document.getElementById("modal-root")!);
  } else return null;
}

export default ModalPortal;
