import { useEffect } from "react";

export default function useAsyncEffect(
  func: () => Promise<any>,
  dependencies?: any[],
  cleanup?: () => void
): void {
  useEffect(() => {
    (async () => {
      await func();
    })();
    if (cleanup) return cleanup();
  }, dependencies);
}
