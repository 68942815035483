import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import styled from "styled-components";
import { Icon, ButtonPrimary } from "prisma-component-library";
import CardGenericContainer from "../Common/Cards/CardGenericContainer";
import { mapApplications } from "../../utils/mapApplications";
const BlockContent = require("@sanity/block-content-to-react");

function SubscriptionsSingle({ subscriptionData, marketplaceData, id }: any) {
  const history = useHistory();
  // find selected subscription from subscriptionData
  const selectedSubscription = subscriptionData.find(
    (item: { title: string }) => item.title.toLowerCase() === id
  );

  const includedApplications = selectedSubscription.applications
    ? marketplaceData.filter((application: { _id: string[] }) =>
        selectedSubscription.applications.includes(application._id)
      )
    : null;

  const selectedItemIndex = subscriptionData.indexOf(selectedSubscription);
  const isLastItem = selectedItemIndex === subscriptionData.length - 1;

  return (
    <Container>
      <div className="title">{selectedSubscription.title}</div>
      <Gradient subscriptionData={selectedSubscription} />
      <TopContent>
        <div className="description">
          <h2>Description</h2>
          <BlockContent blocks={selectedSubscription.content} />
        </div>
        <div className="features">
          {selectedSubscription.body && (
            <div>
              <h2>Main Features</h2>
              <BlockContent blocks={selectedSubscription.features} />
            </div>
          )}
        </div>
      </TopContent>
      {includedApplications && (
        <Applications>
          <h2>Included applications</h2>
          {marketplaceData && selectedSubscription.applications.length > 0 && (
            <CardGenericContainer
              items={mapApplications(includedApplications, history)}
            />
          )}
        </Applications>
      )}

      <Bottom>
        {selectedItemIndex !== 0 && (
          <span className="previous_subscription">
            <Icon name="DOWN_ARROW" rotate={true} />
            <NavLink
              to={`/subscriptions/${subscriptionData[
                selectedItemIndex - 1
              ].title.toLowerCase()}`}
            >
              {`See ${subscriptionData[selectedItemIndex - 1].title} plan`}
            </NavLink>
          </span>
        )}
        <ButtonPrimary
          onClick={() =>
            window.open("mailto:sgylterud@akvagroup.com?subject=PRISM&body=")
          }
        >
          Contact us
        </ButtonPrimary>

        {!isLastItem && (
          <span className="next_subscription">
            <NavLink
              to={`/subscriptions/${subscriptionData[
                selectedItemIndex + 1
              ].title.toLowerCase()}`}
            >
              {`See ${subscriptionData[selectedItemIndex + 1].title} plan`}
            </NavLink>
            <Icon name="DOWN_ARROW" rotate={true} />
          </span>
        )}
      </Bottom>
    </Container>
  );
}

export default SubscriptionsSingle;
const Container = styled.div`
  max-width: 1024px;
  margin: 2.5rem auto;

  .title {
    font-weight: 300;
    font-size: 32px;
    line-height: 48px;
  }
  h2 {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 1024px) {
    margin: 0 2rem;
  }
`;

const TopContent = styled.div`
  display: flex;

  .description {
    flex-grow: 1;
    margin-right: 3rem;
    p {
      margin-bottom: 1rem;
    }
  }

  .features {
    min-width: 254px;
    list-style-position: inside;

    p {
      margin-bottom: 1rem;
    }
    strong {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;

    .features {
      margin-top: 1rem;
    }
  }
`;

const Applications = styled.div`
  margin-bottom: 1rem;
`;
const Gradient = styled.div`
  margin: 1rem 0;
  height: 4px;
  width: 100%;
  background: ${(props: { subscriptionData: { gradient: string } }) =>
    props.subscriptionData.gradient
      ? props.subscriptionData.gradient
      : "linear-gradient(136.48deg, #0c2033 10.29%, #8f00ff 100%)"};
`;

const Bottom = styled.div`
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  position: relative;

  .previous_subscription {
    display: flex;
    align-items: center;

    svg {
      transform: rotate(90deg);
    }
  }
  button {
    position: absolute;
    left: 42%;
  }
  .next_subscription {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    svg {
      transform: rotate(270deg);
      margin: auto;
    }
  }
`;
