import React from "react";
import styled from "styled-components";
import { BarLoader } from "react-spinners";

function Spinner() {
  return (
    <SpinnerContainer>
      <BarLoader color={"#BBBAFA"} />
    </SpinnerContainer>
  );
}

export default Spinner;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
