import React, { useContext } from "react";
import { H1, Body, Anchor } from "prisma-component-library";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import styled from "styled-components";
import ProfileContext from "../../Context";
import Spinner from "../Common/Spinner";
import PersonalInformation from "./PersonalInformation";

function ProfileContainer() {
  const { isLoading } = useContext(ProfileContext);

  return (
    <MainLayout>
      <Container>
        <H1> My Profile</H1>
        <div>
          <Body>
            Profile editing is done through the Veracity platfom.&nbsp;
          </Body>

          <Anchor
            href="https://services.veracity.com/EditProfile"
            target="_new"
          >
            Click here to go into your Veracity Profile
          </Anchor>
        </div>
        <InfoContainer>
          {isLoading ? <Spinner /> : <PersonalInformation />}
        </InfoContainer>
      </Container>
    </MainLayout>
  );
}

export default ProfileContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;

  & > * {
    padding: 0 16px;
    margin-bottom: 16px;
  }
  h1 {
    padding-top: 26px;
  }
`;

const InfoContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 3px;

  > div {
    margin: auto;
  }

  h2 {
    margin-bottom: 26px;
  }

  li {
    list-style: none;
    display: flex;

    div {
      height: 24px;
      width: 100px;
    }
  }
`;
