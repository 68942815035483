import React from "react";
import { NavLink } from "react-router-dom";
import { SUBSCRIPTIONS_PATH, HOME } from "./paths";

export const landingPageLinks = [
  {
    component: (
      <NavLink to={HOME} key="home" exact>
        Home
      </NavLink>
    ),
    type: "main" as const,
    name: "Home",
    item: HOME
  },
  {
    component: (
      <NavLink to={SUBSCRIPTIONS_PATH} key="subscriptions" exact>
        Subscription Plans
      </NavLink>
    ),
    type: "main" as const,
    name: "Subscription plans",
    item: SUBSCRIPTIONS_PATH
  }
];
