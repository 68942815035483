import React from "react";
import styled from "styled-components";
import { H3 } from "prisma-component-library";

function Quote({ quotes }: any) {
  return (
    <div>
      {quotes.map(
        (quote: { imageUrl: string; content: string; author: string }) => {
          return (
            <QuoteContainer>
              <QuoteImage src={quote.imageUrl} />
              <div>
                <QuoteContent>{quote.content}</QuoteContent>
                <QuoteAuthor>
                  <H3>{quote.author}</H3>
                </QuoteAuthor>
              </div>
            </QuoteContainer>
          );
        }
      )}
    </div>
  );
}

export default Quote;

const QuoteContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 952px) {
    flex-direction: column;
  }
`;
const QuoteImage = styled.img`
  border-radius: 50%;
  margin-right: 32px;

  @media screen and (max-width: 952px) {
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

const QuoteContent = styled.div`
  font-style: italic;
`;

const QuoteAuthor = styled.div`
  margin-top: 16px;
`;
