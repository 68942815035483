import React from "react";
import { NavLink } from "react-router-dom";
import {
  DASHBOARD,
  APPLICATIONS,
  MARKETPLACE,
  PROFILE,
  MY_DATA
} from "../../constants/paths";

export const footerLinks = [
  <NavLink to={DASHBOARD} key="dashbaord">
    Dashboard
  </NavLink>,
  <NavLink to={APPLICATIONS} key="applications">
    Applications
  </NavLink>,
  <NavLink to={MY_DATA} key="mydata">
    My Data
  </NavLink>,
  <NavLink to={MARKETPLACE} key="marketplace">
    Marketplace
  </NavLink>,
  <NavLink to={PROFILE} key="profile">
    Profile
  </NavLink>,
  <a
    href="https://services.veracity.com/prisma/support"
    key="contactus"
    target="_blank"
  >
    Contact us
  </a>
];
